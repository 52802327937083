import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';

import linkStyles from './link.module.css';

const LinkComponent = (props) => {
  const { links } = props;

  return (
    <div className={linkStyles.heroLinkContainer}>
      {links.map((objLink) => (
        <Link className={linkStyles.heroLink} key={`text_${objLink.value}`} to={`/${objLink.value}/`}>
          <span>{objLink.label}</span>
          <span className={linkStyles.heroLinkCentre} />
          <span>{'>'}</span>
        </Link>
      ))}
    </div>
  );
};

export default LinkComponent;

LinkComponent.propTypes = {
  links: PropTypes.array.isRequired,
};
