import React from 'react';
import PropTypes from 'prop-types';

import Img from 'gatsby-image';
import { FaPause, FaPlay } from 'react-icons/fa';

import Review from './reviewComponent';

import heroStyles from './hero.module.css';

class HeroComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageNumber: 0,
      paused: false,
    };

    this.timer = null;
    this.timerDuration = 8000;
    this.toggleImage = this.toggleImage.bind(this);
    this.handleTimer = this.handleTimer.bind(this);
    this.startTimer = this.startTimer.bind(this);
  }

  // componentDidMount() {
  //   this.timer = setInterval(this.toggleImage.bind(null, null, true), this.timerDuration);
  // document.getElementById('divPauseContainer')
  // .addEventListener('mouseenter', this.handleTimer.bind(null, 'enter'));
  // document.getElementById('divPauseContainer')
  // .addEventListener('mouseout', this.handleTimer.bind(null, 'leave'));
  // }

  componentWillUnmount() {
    if (!this.timer) {
      return;
    }

    clearInterval(this.timer);
  }

  handleTimer(event) {
    if (!this.timer) {
      return;
    }

    const { paused } = this.state;

    clearInterval(this.timer);

    if (event === 'pause' && !paused) {
      this.setState({ paused: true });
      return;
    }

    this.timer = setInterval(this.toggleImage.bind(null, null, true), this.timerDuration);
    if (event === 'pause' || event === 'restart') {
      this.setState({ paused: false });
    }
  }

  startTimer(index) {
    if (index === 0) {
      this.timer = setInterval(this.toggleImage.bind(null, null, true), this.timerDuration);
    }
  }

  // const data = useStaticQuery(graphql`
  //   query {
  //     file(relativePath: { eq: "images/Lisbon.jpg" }) {
  //       childImageSharp {
  //         # Specify the image processing specifications right in the query.
  //         fluid(quality: 100) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `)

  toggleImage(toggleImage, boolTimer = false) {
    const { imageNumber } = this.state;
    const { imageData } = this.props;
    let toggleImageNumber = toggleImage;

    if (boolTimer) {
      toggleImageNumber = (imageData.length - 1) === imageNumber ? 0 : imageNumber + 1;
    } else if (toggleImageNumber === imageNumber) {
      return;
    }

    if (toggleImageNumber === -1 || toggleImageNumber > (imageData.length - 1)) {
      toggleImageNumber = 0;
    }

    this.handleTimer('restart', toggleImageNumber);
    this.setState({
      imageNumber: toggleImageNumber,
    });
  }

  render() {
    const { imageNumber, paused } = this.state;
    const { images, imageData } = this.props;

    const textData = imageData[imageNumber];
    const navColor = textData.colour;
    const textShadow = `0px 0px 15px ${navColor === '#343434' ? 'white' : 'black'}`;

    return (
      <div id="divScrollContainer" className={heroStyles.heroContainer}>
        <div className={heroStyles.navContainer} style={{ color: navColor }}>
          {imageData.map((objMenuItem, navIndex) => (
            <button type="button" key={`menuButton_${objMenuItem.id}`} aria-label={`Select Image ${navIndex}`} className={heroStyles.buttonContainer} onClick={this.toggleImage.bind(null, navIndex, false)}>
              <div className={`${heroStyles.buttonPill}`} aria-hidden="true" style={{ borderColor: navColor }}>
                <div className={navIndex === imageNumber ? heroStyles.buttonPillSelected : ''} style={{ backgroundColor: navColor }} />
              </div>
              <div className={heroStyles.buttonName} aria-hidden="true" style={{ color: navColor }}>
                {objMenuItem.imageName}
              </div>
            </button>
          ))}
        </div>
        {this.timer
          ? (
            <button type="button" className={heroStyles.pauseContainerButton} aria-label="Pause Slider" onClick={this.handleTimer.bind(this, 'pause')}>
              <div id="divPauseContainer" className={heroStyles.pauseContainer} style={{ color: navColor, borderColor: navColor }}>
                { paused
                  ? <FaPlay />
                  : <FaPause />}
              </div>
            </button>
          )
          : null}

        {imageData.map((data, index) => {
          const objImage = images.find((image) => image.node.base === data.fileName);
          const key = index;
          return (
            <div key={`image_${key}`} className={heroStyles.heroImage}>
              <Img
                fluid={objImage.node.childImageSharp.fluid}
                alt={data.alt}
                fadeIn
                style={{ transition: 'opacity 0.5s linear', opacity: index === imageNumber ? 100 : 0 }}
                className={heroStyles.heroImageWrapper}
                onLoad={() => this.startTimer(index)}
              />
              <div
                className={`${heroStyles.heroTextContainer} ${heroStyles[`heroTextContainer${data.textPos}`]}`}
                style={{ opacity: index === imageNumber ? 100 : 0 }}
              >
                <div style={{ color: navColor, textShadow }}>
                  <h1 className={heroStyles.heroHeader}>{data.header}</h1>
                </div>
                <div style={{ color: navColor, textShadow }}>
                  <p className={heroStyles.heroText}>{data.text}</p>
                </div>
              </div>
              {data.logo
                ? (
                  <div className={heroStyles.heroLogoContainer}>
                    {data.logo.map((logo, logoIndex) => {
                      const logoImage = images.find((image) => image.node.base === logo);
                      const logoKey = `logo_${logoIndex}`;
                      return (
                        <div key={logoKey} className={heroStyles.heroLogoImage}>
                          <Img
                            fluid={logoImage.node.childImageSharp.fluid}
                            alt={`${logoImage.node.base}_logo`}
                            fadeIn
                            style={{ transition: 'opacity 0.5s linear', opacity: index === imageNumber ? 100 : 0 }}
                          />
                        </div>
                      );
                    })}
                  </div>
                )
                : null}
            </div>
          );
        })}
        <div className={heroStyles.heroReviewContainer}>
          <Review
            showAll={false}
            navColor={navColor}
            shadow
            shadowOpacity={textData.shadowOpacity}
          />
        </div>
      </div>
    );
  }
}

export default HeroComponent;

HeroComponent.propTypes = {
  images: PropTypes.array.isRequired,
  imageData: PropTypes.array.isRequired,
};
