import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import { get } from 'lodash';

import Layout from '../components/layout';
import Menu from '../components/menuComponent';
import Hero from '../components/heroComponent';
import LinkComponent from '../components/linkComponent';
import Review from '../components/reviewComponent';

import layoutStyles from '../components/layout.module.css';

import Links from '../data/links';

export default () => {
  const dataImages = useStaticQuery(graphql`
    query MyQuery {
      allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "images/hero"}}) {
        edges {
          node {
            base
            childImageSharp {
              fluid(
                  quality: 80
                  maxWidth: 1240
                ) {
                aspectRatio
                src
                srcSet
                sizes
                tracedSVG
                srcWebp
                srcSetWebp
              }
            }
          }
        }
      }
    }
  `);

  const heroData = [
    {
      id: 0,
      imageName: 'Modern',
      fileName: 'SliderBlackGloss.jpg',
      alt: 'gloss black built-in fitted wardrobe',
      header: 'Specialising in tailor-made furniture',
      // text: 'Wow Interiors design and install fitted storage solutions to Kent, Sussex
      // and South London areas',
      text: 'Design and installation of fitted bedrooms, wardrobes, kitchens, & storage solutions to Kent, Sussex and South London areas',
      textPos: 'Left',
      colour: 'white',
      shadowOpacity: '0.6',
    },
    {
      id: 1,
      imageName: 'Bespoke',
      fileName: 'Lisbon.jpg',
      alt: 'bespoke fitted angled wardobe in white gloss & wood finish',
      header: 'Truly made to measure',
      text: '25 years of experience in the design and fitting of beautiful bespoke fitted furniture',
      textPos: 'Right',
      colour: 'white',
      shadowOpacity: '0.2',
    },
    {
      id: 2,
      imageName: 'Luxury',
      fileName: 'Walk-In.jpg',
      alt: 'walk in wardobe in grey finish',
      header: 'luxury walk-in wardrobes',
      text: 'With multiple options and finishes our designers can design and create you the home you have always wanted.',
      textPos: 'Left',
      colour: 'white',
      shadowOpacity: '0.6',
    },
    {
      id: 3,
      imageName: 'Glamour',
      fileName: 'Kitchen.JPG',
      alt: 'modern stone finish kitchen',
      header: 'High End kitchens',
      text: 'From modern contemporary designs to traditional styles, we have something in our portfolio for all tastes & lifestyles',
      textPos: 'Left',
      colour: 'white',
      logo: ['artego_kuechen_logo.png', 'rempp_logo.png'],
      shadowOpacity: '0.6',
    },
    {
      id: 4,
      imageName: 'Practical',
      fileName: 'Office.jpg',
      alt: 'built in office furniture with desk & storage',
      header: 'Whatever your style or storage requirements',
      text: 'Get in touch today for your no-obligation design appointment. We can make your home interior dreams a reality.',
      textPos: 'Left',
      colour: 'white',
      shadowOpacity: '0.1',
    },
    {
      id: 5,
      imageName: 'Elegant',
      fileName: 'ShakerStorage.jpg',
      alt: 'white painted shaker style fitted bedroom furniture',
      header: 'Variety of options available',
      text: 'Extensive expertise in the production of stylish, space saving, fitted rooms',
      textPos: 'Right',
      colour: '#343434',
      shadowOpacity: '1.0',
    },
  ];

  function mapOrder(array, order, key) {
    array.sort((a, b) => {
      const A = get(a, key);
      const B = get(b, key);
      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      }

      return -1;
    });

    return array;
  }

  const sortedImages = mapOrder(dataImages.allFile.edges, heroData.map((data) => data.fileName), 'node.base');

  return (
    <Layout>
      <Menu />
      <Hero
        images={sortedImages}
        imageData={heroData}
      />
      <div className={layoutStyles.layoutReviewContainer}>
        <Review
          showAll={false}
          navColor="#343434"
          shadow={false}
        />
      </div>
      <LinkComponent
        links={Links}
      />
    </Layout>
  );
};

// {dataImages.allFile.edges.map(({node}) =>
// (
//   <Img fluid={node.childImageSharp.fluid}
//   alt="Fitted double angled wardrobe"
//   fadeIn={false} />
// ))
// }
