module.exports = [
  { label: 'Fitted Bedrooms', value: 'portfolio/bedroom', group: 'bedroom' },
  { label: 'Fitted wardrobes', value: 'portfolio/fitted-wardrobe', group: 'fitted-wardrobe' },
  { label: 'Under stairs storage', value: 'portfolio/under-stairs-storage', group: 'under-stairs-storage' },
  { label: 'Lounge media units', value: 'portfolio/lounge-media-unit', group: 'lounge-media-unit' },
  { label: 'Walk-in wardrobes', value: 'portfolio/walk-in-wardrobe', group: 'walk-in-wardrobe' },
  { label: 'Office & Library', value: 'portfolio/office-library', group: 'office-library' },
  { label: 'Angled built-in wardrobes', value: 'portfolio/angled-wardrobe', group: 'angled-wardrobe' },
  { label: 'Kitchens', value: 'portfolio/kitchen', group: 'kitchen' },
  { label: 'Bespoke storage solutions', value: 'portfolio/bespoke-storage-solutions', group: 'bespoke-storage-solutions' },
];
